exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-data-example-js": () => import("./../../../src/pages/data-example.js" /* webpackChunkName: "component---src-pages-data-example-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-press-and-news-js": () => import("./../../../src/pages/press-and-news.js" /* webpackChunkName: "component---src-pages-press-and-news-js" */),
  "component---src-pages-version-js": () => import("./../../../src/pages/version.js" /* webpackChunkName: "component---src-pages-version-js" */),
  "component---src-templates-bio-template-js": () => import("./../../../src/templates/bioTemplate.js" /* webpackChunkName: "component---src-templates-bio-template-js" */),
  "component---src-templates-press-release-template-js": () => import("./../../../src/templates/pressReleaseTemplate.js" /* webpackChunkName: "component---src-templates-press-release-template-js" */)
}

